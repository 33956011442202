import axios from 'axios';
import React, { useEffect, useState } from 'react'
import AlertMessage from '../components/AlertMessage';
import { API_URLS } from '../api/urls';


interface FormData {
    name: string;
    email: string;
    contact_no: string;
    message: string;
    form_submit_url: string;
    form_name: string;
    country_code: string;
    product_id: any;
}


let productID: string;



export const GetInTouchForm = (productID: any) => {


    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: window.location.href,
        form_name: 'Get In Touch',
        country_code: '',
        product_id: productID.productID
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: '',
        form_name: 'Get In Touch',
        country_code: ''
    });


    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            product_id: productID.productID,
        }));
    }, [productID]);

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Get In Touch',
            country_code: '',
            product_id: ''
        });
        setFormErrors({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: '',
            country_code: ''
        });
    };

    const validateForm = (): boolean => {
        let valid = true;
        const newErrors = {
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Get In Touch',
            country_code: ''
        };

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        } else if (!/^[A-Za-z]+( [A-Za-z]+)*$/.test(formData.name.trim())) {
            newErrors.name = 'Name should not contain numbers';
            valid = false;
        }


        if (!formData.contact_no) {
            newErrors.contact_no = 'Phone number is required';
            valid = false;
        } else if (!/^[+\d]+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number can only contain numbers and + sign';
            valid = false;
        } else if (formData.contact_no.length < 10) {
            newErrors.contact_no = 'Phone number must be at least 10 digits long';
            valid = false;
        } else if (/(\d)\1{5,}/.test(formData.contact_no.trim())) {
            newErrors.contact_no = 'Phone number should not have more than 5 consecutive identical digits';
            valid = false;
        } else if (formData.contact_no.startsWith('+') && !/^\+\d+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number must start with a + followed by digits';
            valid = false;
        } else if (formData.contact_no.length > 15) {
            newErrors.contact_no = 'Phone number cannot exceed 15 digits';
            valid = false;
        }

        if (!formData.message) {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setFormErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);
        try {
            const response = await axios.post(`${API_URLS.FormSubmit}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response) {
                resetForm();
                setAlertMessage({ message: 'Your inquiry has been received. Our team will get back to you soon.', type: 'success' });
            } else {
                setAlertMessage({ message: 'Error while submission', type: 'error' });
            }
        } catch (error) {
            setAlertMessage({ message: `Error while booking: ${error}`, type: 'error' });
        } finally {
            setLoading(false);
        }
    };


    const [alertMessage, setAlertMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(null);


    const closeAlertMessage = () => {
        setAlertMessage(null);
    };


    return (
        <>
            <div className="mb-2">
                <input type="text"
                    value={formData.name} onChange={handleInputChange}
                    id="name"
                    name="name"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Name"
                />
                {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
            </div>

            <div className="mb-2">
                <input type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    id="email"
                    name="email"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Email ID" />
                {/* {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>} */}
            </div>

            <div className="mb-2">
                <select
                    id="country_code"
                    name="country_code"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    value={formData.country_code}
                    onChange={handleInputChange}
                >
                    <option value="">Country Code</option>
                    <option value="+1">+1</option>
                    <option value="+91">+91</option>
                    <option value="+235">+235</option>
                    <option value="+65">+65</option>
                    <option value="+95">+95</option>
                    <option value="+10">+10</option>
                </select>
            </div>

            <div className="mb-2">
                <input type="tel"
                    value={formData.contact_no}
                    onChange={handleInputChange}
                    id="contact_no"
                    name="contact_no"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Contact No"
                />
                {formErrors.contact_no && <p className="text-red-500 text-xs mt-1">{formErrors.contact_no}</p>}
            </div>

            <div className="mb-2">
                <textarea
                    value={formData.message}
                    onChange={handleInputChange}
                    id="message"
                    name="message"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Your message">
                </textarea>
                {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
            </div>
            <div>
                <button type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="block w-full bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-8 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">
                    {loading ? 'Submitting...' : 'Submit'}</button>
            </div>
            <div className="[&_.alert-box]:mx-auto [&_>div]:relative">
                {alertMessage && (
                    <AlertMessage
                        message={alertMessage.message}
                        onClose={closeAlertMessage}
                        type={alertMessage.type}
                    />
                )}
            </div>
        </>
    )
}
