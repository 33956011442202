import React, { useState } from 'react';



interface FAQItem {
  question: string;
  answer: string;
}

export const FAQ = () => {

  const [activeIndex, setActiveIndex] = useState<number | null>(null);


  const questions: FAQItem[] = [
    {
      question: 'Are there any hidden charges while buying medicines from DevMedical.com?',
      answer: 'No, there are no hidden charges when you purchase medicines from DevMedical.com. The price you see at checkout is the final amount you pay, which includes all applicable taxes and shipping fees. DevMedical.com is committed to transparent pricing for a convenient shopping experience. '
    },
    {
      question: 'Does DevMedical offer delivery services?',
      answer: 'Yes, we offer fast and reliable delivery services to ensure that your medical supplies reach you promptly. Delivery options, shipping times, and fees vary based on location and order size, so please check our delivery information or contact customer support for details.'
    },
    {
      question: 'Is there a customer support team to help with product inquiries?',
      answer: 'Yes, our knowledgeable customer support team is available to assist with product questions, order tracking, and any other inquiries you may have. You can reach us via phone, email, or through the contact form on our website.'
    },
    {
      question: 'Can I track my order with DevMedical?',
      answer: 'Once your order has shipped, you’ll receive a tracking number via email. You can use this number to monitor the delivery status of your order through our website or the shipping carrier’s tracking system.'
    },
    {
      question: 'How long does DevMedical delivery take?',
      answer: 'Delivery times for DevMedical orders vary based on product availability, order size, and location. Generally, standard deliveries within our primary service area arrive within 3–7 business days. '
    },
    {
      question: 'What payment methods does DevMedical accept?',
      answer: 'DevMedical accepts a range of payment methods, including credit/debit cards, bank transfers, and online payment options for convenience. We ensure secure transactions for all our customers.'
    }
  ];


  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <section className='py-16'>
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className="faq-container mx-auto">
            <h1 className="xl:text-3xl font-semibold text-primary-900 text-center lg:mb-10 mb-6">Frequently Asked Questions</h1>
            <div className="space-y-4">
              {questions.map((item, index) => (
                <div key={index} className="border border-slate-200 rounded-lg overflow-hidden duration-300">
                  <button
                    onClick={() => toggleAccordion(index)}
                    className="w-full text-left px-4 py-2 bg-gray-50 flex justify-between items-center focus:outline-none duration-300"
                  >
                    <h3 className='font-montserrat lg:text-xl text-lg font-semibold text-primary-900'>{item.question}</h3>
                    <span className='text-2xl font-normal w-10 h-10 flex justify-center items-center'>{activeIndex === index ? '-' : '+'}</span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-4 bg-white duration-300">
                      <p className='text-slate-700'>{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}