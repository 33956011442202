


import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { ProductDetails } from './pages/productDetails';
import { ProductListing } from './pages/productListing';
import { SaltListing } from './pages/saltListing';
import { Disease } from './pages/disease';
import { AllSalts } from './pages/allSalts';
import { AboutUs } from './pages/aboutUs';
import { FAQ } from './pages/faq';
import { ContactUs } from './pages/contactUs';
import { SearchWiseProductListing } from './pages/searchWiseProductListing';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  // State to track if the class is active
  const [isClassActive, setIsClassActive] = useState(false);

  // Function to toggle the state (passed to Header)
  const toggleClass = () => {
    setIsClassActive((prev) => !prev);
  };

  return (

    <Router>
      <div className={`App ${isClassActive ? 'active-class' : ''}`}>
        {/* Passing toggleClass to Header as a prop */}
        <Header onButtonClick={toggleClass} />
        <main className='[&_h2]:font-montserrat [&_h3]:font-montserrat [&_h4]:font-montserrat [&_h5]:font-montserrat font-roboto'>
          <Routes>
            <Route path='' element={<Home />} />
            {/* <Route path='productDetails' element={<ProductDetails />} /> */}
            <Route path="/products/:slug" element={<ProductDetails />} />
            {/* <Route path='/product/:slug' element={<ProductListing />} /> */}
            <Route path="/categories/:slug" element={<ProductListing />} />
            <Route path="/groups/:slug" element={<ProductListing />} />
            <Route path='/group/:slug' element={<SaltListing />} />
            <Route path='/groups' element={<Disease />} />
            <Route path='/categories' element={<AllSalts />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/search' element={<SearchWiseProductListing />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  
  );

//  <Router basename="/index1">
//       <div className={`App ${isClassActive ? 'active-class' : ''}`}>
        
//         <Header onButtonClick={toggleClass} />
//         <main className='[&_h2]:font-montserrat [&_h3]:font-montserrat [&_h4]:font-montserrat [&_h5]:font-montserrat font-roboto'>
//           <Routes>
//             <Route path='' element={<Home />} />
//             <Route path="/products/:slug" element={<ProductDetails />} />
//             <Route path="/categories/:slug" element={<ProductListing />} />
//             <Route path="/groups/:slug" element={<ProductListing />} />
//             <Route path='/group/:slug' element={<SaltListing />} />
//             <Route path='/groups' element={<Disease />} />
//             <Route path='/categories' element={<AllSalts />} />
//             <Route path='/about' element={<AboutUs />} />
//             <Route path='/faq' element={<FAQ />} />
//             <Route path='/contact' element={<ContactUs />} />
//           </Routes>
//         </main>
//         <Footer />
//       </div>
//     </Router>  
//    )

}

export default App;
