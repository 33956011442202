import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URLS } from '../api/urls';
import Select from 'react-select';
import { AutoModal } from '../modals/autoModal';
import { HeaderModal } from '../modals/headerModal';
// import './translate.css';

interface HeaderProps {
    onButtonClick: () => void;
    saltData: any[];
}


declare var google: any;


export const Header = ({ onButtonClick, saltData }: any) => {


    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [clickedIndex, setClickedIndex] = useState<number | null>(null);
    const [languages, setLanguages] = useState<{ value: string; label: string }[]>([]);

    const [isModalOpen, setModalOpen] = useState(false);
    const [headerData, setHeaderData] = useState<any>();
    // const [allSalts, setAllSalts] = useState<any[]>([]);


    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);


    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleClick = (index: number) => {
        setClickedIndex(index);
        setClickedIndex(clickedIndex === index ? null : index);
    };


    const [groups, setGroups] = useState<any[]>([]);

    const fetchAllSalts = async () => {
        try {
            const firstPageResponse = await axios.get(`${API_URLS.GroupList}?page=1`);
            const { last_page } = firstPageResponse.data.meta;
            let allSalts = firstPageResponse.data.data;

            const pageRequests = [];
            for (let page = 2; page <= last_page; page++) {
                pageRequests.push(axios.get(`${API_URLS.GroupList}?page=${page}`));
            }

            const responses = await Promise.all(pageRequests);

            responses.forEach((response) => {
                allSalts = allSalts.concat(response.data.data);
            });

            setGroups(allSalts);
        } catch (error) {
            console.error('Error fetching salts data:', error);
        }
    };

    useEffect(() => {
        fetchAllSalts();
    }, []);



    // const fetchSalts = async () => {
    //     try {
    //         const response = await axios.get(API_URLS.AllSalt);
    //         const data = response.data.data;

    //         setAllSalts(data);
    //     } catch (error) {
    //         console.error('Error fetching salts data:', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchSalts();
    // }, []);



    const closeHover = (event: any) => {
        setHoveredIndex(null);
    }



    useEffect(() => {
        const fetchLanguages = () => {
            const availableLanguages = [
                { value: 'en', label: 'English' },
                { value: 'es', label: 'Spanish' },
                { value: 'fr', label: 'French' },
                { value: 'de', label: 'German' },
                { value: 'zh-CN', label: 'Chinese (Simplified)' },
                { value: 'ja', label: 'Japanese' },
            ];
            setLanguages(availableLanguages);
        };

        fetchLanguages();
    }, []);



    // useEffect(() => {
    //     (window as any).googleTranslateElementInit = () => {
    //         if ((window as any).google && (window as any).google.translate) {
    //             const translateElement = document.getElementById('google_translate_element');
    //             if (translateElement && !translateElement.childElementCount) {
    //                 new (window as any).google.translate.TranslateElement(
    //                     {
    //                         pageLanguage: 'en',
    //                         layout: (window as any).google.translate.TranslateElement.InlineLayout.SIMPLE,
    //                         autoDisplay: false,
    //                     },
    //                     'google_translate_element'
    //                 );
    //             }
    //         }
    //     };

    //     const existingScript = document.getElementById('google-translate-script');
    //     if (!existingScript) {
    //         const googleTranslateScript = document.createElement('script');
    //         googleTranslateScript.id = 'google-translate-script';
    //         googleTranslateScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //         googleTranslateScript.async = true;
    //         googleTranslateScript.onerror = () => console.error("Failed to load Google Translate script");
    //         document.body.appendChild(googleTranslateScript);
    //     }

    //     return () => {
    //         delete (window as any).googleTranslateElementInit;
    //         const translateElement = document.getElementById('google_translate_element');
    //         if (translateElement) {
    //             translateElement.innerHTML = '';
    //         }
    //     };
    // }, []);


    // useEffect(() => {
    //     // Initialize the Google Translate widget
    //     (window as any).googleTranslateElementInit = () => {
    //       if ((window as any).google && (window as any).google.translate) {
    //         const translateElement = document.getElementById('google_translate_element');
    //         if (translateElement && !translateElement.childElementCount) {
    //           new (window as any).google.translate.TranslateElement(
    //             {
    //               pageLanguage: 'en',
    //               layout: (window as any).google.translate.TranslateElement.InlineLayout.SIMPLE,
    //               autoDisplay: false,
    //             },
    //             'google_translate_element'
    //           );
    //         }
    //       }
    //     };

    //     // Dynamically load the Google Translate script
    //     const existingScript = document.getElementById('google-translate-script');
    //     if (!existingScript) {
    //       const googleTranslateScript = document.createElement('script');
    //       googleTranslateScript.id = 'google-translate-script';
    //       googleTranslateScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //       googleTranslateScript.async = true;
    //       googleTranslateScript.onerror = () => console.error("Failed to load Google Translate script");
    //       document.body.appendChild(googleTranslateScript);
    //     }

    //     // Create a MutationObserver to detect when the iframe is added to the DOM
    //     const observer = new MutationObserver(() => {
    //       // Look for the iframe injected by Google Translate
    //       const iframe: any = document.querySelector('iframe.VIpgJd-ZVi9od-xl07Ob-OEVmcd.skiptranslate');
    //       if (iframe) {
    //         // Apply styles to the iframe once it is added
    //         iframe.style.width = '100%';          // Adjust width of iframe
    //         iframe.style.height = '300px';        // Adjust height based on your design
    //         iframe.style.border = 'none';         // Remove the border
    //         iframe.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)';  // Optional box-shadow for styling
    //         iframe.style.borderRadius = '8px';    // Optional rounded corners
    //         iframe.style.display = 'block';       // Block-level to center
    //         iframe.style.margin = '0 auto';       // Center horizontally
    //         iframe.style.overflow = 'scroll';      


    //         // Apply styles to enable mobile horizontal scrolling (for the parent container)
    //         const translateElement: any = document.getElementById('google_translate_element');
    //         if (translateElement) {
    //           translateElement.style.position = 'relative';  // Ensure container positioning
    //           translateElement.style.overflowX = 'auto';     // Enable horizontal scrolling
    //           translateElement.style.webkitOverflowScrolling = 'touch';  // Smooth scrolling for mobile
    //         }

    //         // Stop observing once the iframe has been styled
    //         observer.disconnect();
    //       }
    //     });

    //     // Start observing the document body for changes
    //     observer.observe(document.body, {
    //       childList: true,
    //       subtree: true,  // Watch the entire DOM for changes
    //     });

    //     // Cleanup the observer and Google Translate script when the component is unmounted
    //     return () => {
    //       observer.disconnect();
    //       delete (window as any).googleTranslateElementInit;
    //       const translateElement = document.getElementById('google_translate_element');
    //       if (translateElement) {
    //         translateElement.innerHTML = ''; // Clean up the translate element when unmounting
    //       }
    //     };
    //   }, []);


    // useEffect(() => {
    //     // Dynamically load the Google Translate script
    //     const script = document.createElement('script');
    //     script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //     script.async = true;
    //     document.body.appendChild(script);

    //     // Global callback function for Google Translate
    //     (window as any).googleTranslateElementInit = () => {
    //         new (window as any).google.translate.TranslateElement(
    //             { pageLanguage: 'en' },
    //             'google_translate_element'
    //         );
    //     };

    //     return () => {
    //         document.body.removeChild(script); // Clean up the script when the component is unmounted
    //     };
    // }, []);


    useEffect(() => {
        // Check if the Google Translate script is already added
        const existingScript = document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');

        if (!existingScript) {
            // Dynamically load the Google Translate script
            const script = document.createElement('script');
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            script.async = true;
            document.body.appendChild(script);

            // Global callback function for Google Translate
            (window as any).googleTranslateElementInit = () => {
                new (window as any).google.translate.TranslateElement(
                    { pageLanguage: 'en' },
                    'google_translate_element'
                );
            };

            return () => {
                // Clean up the script when the component is unmounted
                document.body.removeChild(script);
                delete (window as any).googleTranslateElementInit;
            };
        } else {
            // If the script already exists, manually initialize the widget
            if ((window as any).google && (window as any).google.translate) {
                new (window as any).google.translate.TranslateElement(
                    { pageLanguage: 'en' },
                    'google_translate_element'
                );
            }
        }
    }, []);



    const fetchHeaderProduct = async () => {
        try {
            const response = await axios.get(`${API_URLS.TopSellingProducts}`);
            const data = response.data.data;

            const getRandomProducts = (arr: any, num: any) => {
                const shuffled = arr.sort(() => 0.5 - Math.random());
                return shuffled.slice(0, num);
            };

            const randomProducts = getRandomProducts(data, 2);
            setHeaderData(randomProducts);
        } catch (error) {
        }
    };



    useEffect(() => {
        fetchHeaderProduct();
    }, []);


    return (
        <>
            <header className='max-lg:sticky max-lg:top-0 z-[11] lg:sticky lg:top-0'>
                <div className="header-top py-2 bg-white">
                    <div className='max-w-[1450px] mx-auto px-4 lg:relative'>
                        <div className="xs:flex max-lg:justify-center items-center">
                            <div className="custom-logo flex-none 2xl:w-[380px] xl:w-[350px] md:w-[280px] xs:w-[240px] w-[220px]">
                                <Link to="/" aria-label="Dev Medical"><img src="/assets/images/logo-dev-medical.svg" alt="Dev Medical" loading="lazy" width={380} height={58} /></Link>
                            </div>
                            <div className='w-full lg:ml-4 xs:ml-2 max-xs:flex max-xs:mt-2'>

                                <div className="text-right [&_img]:inline-block sm:mb-2 mr-auto">
                                    <div className="flex justify-end gap-1">
                                        <img src="/assets/images/google-translate.svg" alt="Google Translate" width={24} height={24} className='w-6' />
                                        <div id="google_translate_element" className=' [&_select]:bg-gray-50 
                                    [&_select]:border 
                                    [&_select]:border-gray-300 
                                    [&_select]:text-gray-900 
                                    [&_select]:text-sm 
                                    [&_select]:rounded 
                                    [&_select]:px-2.5 
                                    [&_select]:py-1.5 
                                    [&_select]:w-[150px]
                                    focus:[&_select]:ring-primary-500 
                                    focus:[&_select]:border-primary-500 
                                    focus:[&_select]:outline-none 
                                    focus:[&_select]:border-[#ff8629]'></div>
                                    </div>
                                </div>
                                {/* <div id="google_translate_element"></div> */}
                                <ul className='flex items-center xs:justify-end justify-center xl:gap-4 lg:gap-2 max-lg:gap-3 '>
                                    <li>
                                        <a href='tel:+919011200003' className='flex items-center gap-x-1' aria-label="Call by phone">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.16 340.16" xmlSpace="preserve" className='md:w-6 md:h-6 w-5 h-5 fill-primary-900'>
                                                <g>
                                                    <path d="M8.35,102.71c-0.56-12.44,4.27-24.39,15.11-34.1c7.31-6.55,13.95-13.85,21-20.7c12.03-11.71,24.91-11.68,36.86,0.13 
                c12.32,12.18,24.58,24.44,36.75,36.77c11.95,12.11,11.91,25.17-0.04,37.33c-7.13,7.25-14.29,14.46-21.62,21.5
                c-2.72,2.61-3.11,4.92-1.54,8.35c7.28,15.96,17.77,29.69,29.45,42.56c18.36,20.23,38.39,38.37,63.39,50.31
                c3.62,1.73,5.92,1.57,8.71-1.36c7.01-7.36,14.27-14.47,21.5-21.61c12.25-12.09,25.01-12.14,37.13-0.13
                c12.3,12.2,24.58,24.43,36.79,36.73c12.11,12.2,12.04,25.42-0.08,37.68c-7.49,7.58-15,15.16-22.51,22.73
                c-11.26,11.35-25.15,14.1-40.42,12.39c-33.91-3.81-63.23-19.11-90.85-37.67c-55.03-36.99-96.41-85.5-120.84-147.67
                C11.93,132.69,8.4,119.01,8.35,102.71z" />
                                                    <path d="M331.85,161.21c-9.2,1.59-17.79,3.08-26.85,4.64c-6.16-34.09-21.05-63.48-45.93-87.45c-24.7-23.79-54.23-37.99-88.71-43.09
                c1.26-8.98,2.47-17.64,3.77-26.91c39.99,5.54,74.23,21.82,102.98,49.45C306.61,86.19,324.92,120.47,331.85,161.21z" />
                                                    <path d="M266.32,165.59c-8.63,1.47-17.34,2.95-26.14,4.45c-8.91-39.86-33.3-62.99-73-70.94c1.2-8.65,2.41-17.31,3.6-25.86
                C209.49,69.69,265.89,124.19,266.32,165.59z" />
                                                </g>
                                            </svg>
                                            <span className='2xl:text-lg xl:text-base text-sm font-semibold text-primary-900 max-lg:hidden'>+91 9011200003</span>
                                        </a>

                                    </li>
                                    <li>
                                        <a href='mailto:info@devmedicos.com' className='flex items-center gap-x-1' aria-label="Email us">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='md:w-6 md:h-6 w-5 h-5 fill-primary-900'>
                                                <g>
                                                    <path d="M52.06,10.33c-1.82,1.78-3.65,3.54-5.45,5.34C41.05,21.24,35.5,26.83,29.94,32.4c-1.12,1.13-2.07,1.13-3.18,0.01
                c-7.22-7.25-14.44-14.5-21.65-21.76c-0.14-0.14-0.27-0.3-0.54-0.6c0.79-0.13,1.42-0.33,2.05-0.33c14.48-0.02,28.97-0.02,43.45,0
                c0.63,0,1.27,0.19,1.9,0.3C52,10.12,52.03,10.23,52.06,10.33z"/>
                                                    <path d="M4.7,46.41c5.3-5.22,10.61-10.45,15.96-15.72c1.33,1.34,2.75,2.79,4.2,4.21c1.45,1.42,3.19,1.83,5.09,1.15
                c0.74-0.27,1.44-0.78,2.02-1.32c1.43-1.34,2.78-2.76,4.07-4.04c5.34,5.27,10.65,10.5,15.95,15.72c0,0.08,0,0.17-0.01,0.25
                c-0.71,0.1-1.41,0.3-2.12,0.3c-7.41,0.02-14.81,0.02-22.22,0.02c-6.94,0-13.88,0.01-20.82-0.01c-0.7,0-1.41-0.19-2.11-0.28
                C4.71,46.59,4.7,46.5,4.7,46.41z"/>
                                                    <path d="M54.48,44.28c-5.45-5.38-10.76-10.62-16.09-15.88c5.37-5.3,10.69-10.56,16.1-15.9c0.1,0.67,0.29,1.43,0.3,2.19
                c0.03,4.44,0.02,8.87,0.02,13.31c0,4.69,0.01,9.39-0.02,14.08C54.78,42.8,54.59,43.53,54.48,44.28z"/>
                                                    <path d="M18.32,28.37C12.97,33.66,7.65,38.91,2.23,44.26c-0.11-0.69-0.32-1.4-0.33-2.11c-0.03-3.74-0.02-7.47-0.02-11.21
                c0-5.44-0.01-10.88,0.01-16.32c0-0.73,0.19-1.45,0.29-2.18C7.61,17.79,12.93,23.04,18.32,28.37z"/>
                                                </g>
                                            </svg>
                                            <span className='2xl:text-lg xl:text-base text-sm font-semibold text-primary-900 max-lg:hidden'>info@devmedicos.com</span>
                                        </a>

                                    </li>
                                    <li>
                                        <a href="whatsapp://send?phone=9011200003" className='flex items-center gap-x-1' aria-label="WhatsApp">
                                            <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 308 308" xmlSpace="preserve" className='md:w-6 md:h-6 w-5 h-5 fill-green-500'>
                                                <g id="XMLID_468_">
                                                    <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                          c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                          c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                          c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                          c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                          c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                          c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                          c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                          c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                          C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                                    <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                          c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                          c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                          M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                          l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                          c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                          C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                                </g>
                                            </svg>
                                            <span className='2xl:text-lg xl:text-base text-sm font-semibold text-primary-900 max-lg:hidden'>Chat with Us</span>
                                        </a>
                                    </li>
                                    <li className='font-montserrat '>
                                        <button onClick={openModal} className='flex items-center px-1 py-1 text-white rounded-md bg-[#ff8629] hover:bg-orange-500' aria-label="Request A Quote">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 283.46 283.46" xmlSpace="preserve" className='md:w-8 md:h-8 w-5 h-5 fill-[#ff8629] bg-white rounded-sm'>
                                                <g>
                                                    <path d="M170.29,205.44c0-5.19,0.05-10.18-0.03-15.18c-0.02-1.58,0.21-2.75,1.81-3.52c1.36-0.65,2.5-1.74,3.82-2.51
                        c3.06-1.77,5.16-4.36,6.68-7.49c0.32-0.65,0.77-1.23,1.38-2.19c0.11,0.73,0.19,1.04,0.19,1.35c0,11.95,0,23.9,0,35.85
                        c0,3.18-1.18,5.75-4.22,7.01c-1.23,0.51-2.7,0.61-4.06,0.62c-8.96,0.05-17.92,0.03-26.89,0.03c-25.5,0-51,0.03-76.49-0.06
                        c-1.84-0.01-3.87-0.55-5.47-1.44c-2.16-1.2-2.7-3.47-2.69-5.94c0.05-16.4,0.02-32.79,0.02-49.19c0-30.08,0.1-60.17-0.08-90.25
                        c-0.04-6.21,3.67-8.51,8.5-8.5c34.25,0.06,68.5,0.03,102.75,0.04c5.93,0,8.37,2.71,8.71,8.63c0.34,5.81-0.71,10.97-4.06,15.87
                        c-3.16,4.61-5.67,9.67-8.48,14.52c-0.3,0.52-0.7,0.98-1.37,1.93c0-9.28,0-18.04,0-26.97c-30.82,0-61.42,0-92.12,0
                        c0,42.5,0,84.85,0,127.39C108.83,205.44,139.42,205.44,170.29,205.44z"/>
                                                    <path d="M196.64,81.46c9.08,1.35,16.69,5.15,21.72,13.22c1.11,1.78,1.12,3.53,0.01,5.45c-9.98,17.2-19.89,34.45-29.83,51.67
                        c-4.36,7.56-8.59,15.21-13.19,22.63c-1.24,2-3.46,3.51-5.47,4.89c-6,4.12-12.13,8.05-18.2,12.07c-1.51,1-3.06,1.83-4.82,0.76
                        c-1.69-1.02-1.62-2.77-1.53-4.46c0.43-8.03,0.69-16.08,1.41-24.09c0.22-2.42,1.42-4.89,2.65-7.07
                        c9.05-15.89,18.22-31.71,27.37-47.55c4.68-8.1,9.49-16.12,14.06-24.28C192.19,82.27,194.04,81.46,196.64,81.46z M166.65,175.48
                        c-4.18-4.57-8.92-7.5-15.04-8.62c-0.19,2.7-0.43,5.17-0.48,7.63c-0.01,0.49,0.65,1.14,1.17,1.46c2.06,1.26,4.16,2.47,6.3,3.59
                        c0.48,0.25,1.33,0.31,1.76,0.05C162.43,178.34,164.41,176.96,166.65,175.48z"/>
                                                    <path d="M124.98,118.47c8.46,0,16.92-0.06,25.37,0.02c4.56,0.05,7.08,2.65,7.06,6.92c-0.02,3.95-2.8,6.76-7.12,6.78
                        c-17.44,0.07-34.87,0.06-52.31,0.01c-4.2-0.01-7.13-2.93-7.19-6.78c-0.06-3.88,2.53-6.76,6.76-6.88c6.99-0.21,14-0.12,20.99-0.16
                        c2.15-0.01,4.29,0,6.44,0C124.98,118.41,124.98,118.44,124.98,118.47z"/>
                                                    <path d="M123.99,105.63c-8.47,0-16.94-0.03-25.41,0.02c-2.91,0.01-5.33-0.78-6.87-3.42c-2.6-4.45,0.47-10.27,5.64-10.3
                        c17.91-0.09,35.82-0.07,53.72-0.01c3.6,0.01,6.28,2.98,6.35,6.63c0.08,3.91-2.36,6.9-6.15,6.96c-9.09,0.13-18.19,0.04-27.28,0.04
                        C123.99,105.58,123.99,105.6,123.99,105.63z"/>
                                                    <path d="M111.07,158.77c-4.23,0-8.46,0.02-12.69,0c-4.43-0.03-7.58-2.87-7.62-6.82c-0.04-4.06,3.11-7.05,7.61-7.07
                        c8.46-0.04,16.92-0.04,25.38,0c4.2,0.02,7.29,3.03,7.26,6.94c-0.03,3.98-3.05,6.9-7.25,6.94
                        C119.53,158.8,115.3,158.77,111.07,158.77z"/>
                                                    <path d="M111.76,181.81c1.46,0.75,2.93,1.33,4.19,2.2c0.98,0.67,1.66,1.75,2.54,2.58c0.48,0.44,1.09,1.01,1.67,1.04
                        c5.87,0.29,11.75,0.46,17.62,0.73c1.61,0.07,2.56,1,2.5,2.71c-0.07,1.86-1.4,2.38-2.87,2.23c-3.23-0.32-6.45-0.84-9.67-1.29
                        c-3.01-0.42-5.93-0.21-8.73,1.11c-2.91,1.38-4.88,0.42-5.78-3.07c-1.31,1.7-2.7,3.71-4.84,2.12c-0.82-0.61-0.74-2.42-1.07-3.69
                        c-0.34,0.31-0.94,0.84-1.52,1.4c-1.15,1.12-2.6,1.16-3.5,0.11c-0.55-0.64-0.52-2.18-0.17-3.1c0.53-1.37,1.57-2.55,2.39-3.81
                        c-0.15-0.14-0.29-0.27-0.44-0.41c-1.02,1.09-2.1,2.14-3.06,3.28c-2.94,3.5-5.84,7.04-8.76,10.56c-0.27,0.32-0.52,0.65-0.81,0.95
                        c-1.15,1.16-2.49,1.31-3.74,0.34c-1.37-1.06-0.89-2.37-0.01-3.48c3.17-3.94,6.27-7.94,9.62-11.73c2.89-3.27,5.97-6.38,9.19-9.32
                        c1.19-1.09,2.95-1.76,4.56-2.15c1.65-0.4,2.98,0.58,2.85,2.38c-0.15,2.02-0.69,4.03-1.14,6.02
                        C112.64,180.16,112.24,180.76,111.76,181.81z"/>
                                                </g>
                                            </svg>
                                            <span className='uppercase pl-2 pr-2 xl:text-base text-sm font-semibold max-sm:hidden'>Request A Quote</span>
                                        </button>
                                    </li>
                                    <li className='block lg:hidden max-xs:absolute top-0 right-0'>
                                        <button type='button' onClick={onButtonClick} className='border-0 bg-transparent w-20 h-10 flex justify-center items-center' aria-label="Toggle Menu">
                                            <span className='mr-1 font-semibold uppercase'>Menu</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='w-6 h-6'><path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path></svg>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="header-middle text-white bg-primary-900 lg:block z-[12] font-montserrat max-lg:fixed max-lg:inset-0 max-lg:invisible max-lg:opacity-0 max-lg:bg-opacity-70 duration-200">
                    <button className='close-btn w-6 h-6 border-0 bg-transparent lg:hidden absolute top-2 right-2 z-10' onClick={onButtonClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path></svg>
                    </button>
                    <div className='main-menu max-w-[1450px] mx-auto lg:px-4 max-lg:w-[280px] max-lg:overflow-y-auto max-lg:absolute max-lg:top-0 max-lg:bottom-0 max-lg:right-0 max-lg:bg-primary-800 max-lg:duration-200'>
                        <ul className='navigation-menu 
                    relative 
                    flex 
                    max-lg:flex-col 
                    flex-wrap 
                    lg:items-center 
                    lg:justify-between 
                    [&_>li]:font-semibold 
                    [&_li]:uppercase
                    [&_li_button]:text-left 
                    [&*>:a]:block
                    [&_li_a]:cursor-pointer 
                    [&_li_ul_li_a:hover]:translate-x-2 
                    [&_li_a]:relative 
                    [&_li_a]:duration-150 
                    [&_li_a_i]:absolute 
                    [&_li_a_i]:right-6 
                    [&_li_a:hover]:text-primary-400 
                    [&_li.active_>a]:text-primary-400 
                    [&_li.active_>a_i]:rotate-180 
                    [&_>li_>ul_li]:font-medium 
                    [&_>li_>ul_li]:capitalize 
                    [&>*:not(:first-of-type)_>a]:px-[48px] 
                    max-[1480px]:[&>*:not(:first-of-type)_>a]:px-[42px] 
                    max-[1360px]:[&>*:not(:first-of-type)_>a]:px-[39px] 
                    max-[1300px]:[&>*:not(:first-of-type)_>a]:px-[32px] 
                    max-[1220px]:[&>*:not(:first-of-type)_>a]:px-[30px] 
                    max-[1180px]:[&>*:not(:first-of-type)_>a]:px-[20px] 
                    [&>*:last-of-type_a]:!pr-0                   
                    max-lg:bg-primary-800 
                    max-lg:[&_li_a]:block 
                    lg:[&>*]:py-2 
                    [&_li_a]:py-2 
                    [&_li_span]:block 
                    [&_li_span]:py-2                      
                    [&_ul_li_a]:block
                    [&_ul_li_a]:text-sm
                    [&_ul_li_button]:block 
                    [&_ul_li_button]:w-full 
                    max-lg:[&>*_a]:px-[20px] 
                    max-lg:pt-8 
                    max-lg:[&>*:not(:last-of-type)]:border-b 
                    max-lg:[&>*:not(:last-of-type)]:border-[#0c68a3]
                    [&_li.current-page_a]:text-primary-300
                    '>
                            <li onClick={onButtonClick}>
                                <Link to='/' aria-label="Home Icon">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 243.78 243.78" xmlSpace="preserve" className='w-6 h-6 fill-white'>
                                        <g>
                                            <path d="M121.93,50.62c10.4,9.19,20.74,18.3,31.06,27.43c15.99,14.15,32.03,28.26,47.88,42.58c1.63,1.47,2.92,4.26,2.93,6.44
                      c0.18,32.6,0.14,65.21,0.12,97.81c0,6.14-1.27,7.46-7.24,7.48c-16.05,0.03-32.1,0.01-48.96,0.01c0-1.93,0-3.61,0-5.29
                      c0-12.37,0.01-24.73-0.01-37.1c-0.01-6.65-1.33-8.02-7.76-8.04c-12.37-0.02-24.73-0.02-37.1,0.01c-5.75,0.01-7.12,1.35-7.14,7.09
                      c-0.04,12.53-0.01,25.06-0.02,37.58c0,1.73,0,3.45,0,5.44c-1.54,0.13-2.62,0.29-3.7,0.29c-15.26,0.02-30.52,0.04-45.77,0
                      c-5.31-0.01-6.99-1.62-7-6.82c-0.04-33.09-0.06-66.17,0.07-99.26c0.01-1.67,0.95-3.81,2.19-4.92
                      c26.25-23.39,52.61-46.67,78.96-69.96C120.68,51.19,121.01,51.09,121.93,50.62z"/>
                                            <path d="M239.03,113.81c0,0-8.16,9.19-12.39,13.97c-35.09-31.06-69.91-61.88-105.01-92.95C86.64,65.8,51.93,96.54,16.64,127.78
                      c-4.05-4.56-8.02-9.02-12.27-13.82c2.54-2.28,4.78-4.3,7.03-6.29C46.73,76.35,82.1,45.08,117.32,13.64c3.36-3,5.23-2.97,8.59,0.03
                      c36.68,32.7,73.53,65.21,110.35,97.76C237.09,112.15,239.03,113.81,239.03,113.81z"/>
                                            <path d="M174.65,25.46c9.89,0,19.28,0,28.97,0c0,14.3,0,28.35,0,43.18c-1.53-1.19-2.75-2.04-3.85-3.03
                      c-7.28-6.55-14.46-13.22-21.85-19.65c-2.4-2.09-3.49-4.27-3.32-7.44C174.81,34.39,174.65,30.22,174.65,25.46z"/>
                                        </g>
                                    </svg>
                                </Link>
                            </li>
                            <li onClick={onButtonClick}><Link to='/about'>About Us</Link></li>
                            <li className='hidden lg:block'>|</li>



                            <li
                                className={`${hoveredIndex === 1 ? 'hovered' : ''} ${clickedIndex === 1 ? 'active' : ''}`}
                                onMouseEnter={() => handleMouseEnter(1)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => {
                                    handleClick(1);
                                    onButtonClick();
                                }}
                            >
                                <Link to='/groups'>Products</Link>
                                <ul className="megamenu-row max-lg:max-h-[350px] max-lg:overflow-y-auto lg:flex flex-wrap lg:absolute top-full lg:left-0 lg:right-0 relative text-primary-900 bg-[#d6f2ff] invisible opacity-0 max-lg:h-0 [&_li_ul_li_:not(:last-of-type)]:border-b [&_li_ul_li:not(:last-of-type)]:border-primary-600 shadow duration-200 lg:translate-y-5">
                                    <li className="xl:w-2/12 lg:w-3/12 w-full hidden lg:block lg:px-4 lg:py-4  bg-[#91d5f1]">
                                        <ul className="list-unstyled list-dashed lg:-mx-4 lg:flex flex-wrap relative">
                                            <li className="w-full lg:px-4">
                                                <ul className='flex flex-col gap-y-4'>
                                                    {headerData && headerData.length > 0 ? (
                                                        headerData.map((product: any) => {


                                                            const imageFilename = product.product_images?.split(',')[0];
                                                            const encodedImageFilename = imageFilename ? encodeURIComponent(imageFilename) : null;

                                                            const imageUrl = encodedImageFilename
                                                                ? `/admin/storage/image/product/${encodedImageFilename}`
                                                                : '/assets/images/no-img.webp';
                                                            return (
                                                                <li className='!border-b-0' key={product.id}>
                                                                    <div className="p-3 rounded shadow bg-white text-center [&_img]:inline-block !flex flex-col gap-y-4 h-full">
                                                                        <div className="text-center h-[125px] [&_img]:w-full [&_img]:h-full [&_img]:object-contain !border">
                                                                            <Link to={`/products/${product.product_url_slug}`} className='block h-full w-full hover:!translate-x-0'>
                                                                                <img
                                                                                    src={imageUrl}
                                                                                    alt={product.product_name || 'No image available'}
                                                                                    onError={(e: any) => {
                                                                                        e.target.src = '/assets/images/no-img.webp';
                                                                                    }}
                                                                                    width={185} height={185} loading="lazy"
                                                                                />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="flex flex-col gap-y-1">
                                                                            <h3 className="!font-roboto text-base text-[#777777] line-clamp-2 cursor-pointer">
                                                                                {product.product_name}
                                                                            </h3>
                                                                            {product.price ?
                                                                                <p className="font-roboto text-base font-bold text-black">
                                                                                    Price : ₹ {product.price} {product.product_units ? '/' : null} {product.product_units}
                                                                                </p> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <p>No products available</p>
                                                    )}
                                                </ul>



                                            </li>
                                        </ul>
                                    </li>
                                    <li className="xl:w-10/12 lg:w-9/12 w-full lg:px-4 lg:py-4 ">

                                        <ul className="list-unstyled list-dashed lg:-mx-4 lg:flex flex-wrap relative">
                                            {groups
                                                .slice()
                                                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                                                .map((group: any) => (
                                                    <li key={group.id} className="lg:w-3/12 w-full lg:px-4 capitalize">
                                                        <button className="capitalize" onClick={closeHover}>
                                                            <Link to={`/group/${group.url_slug}`}>{group.name}</Link>
                                                        </button>
                                                    </li>
                                                ))}
                                        </ul>

                                    </li>
                                </ul>

                            </li>


                            <li className='hidden lg:block'>|</li>
                            <li onClick={onButtonClick}><Link to='/categories'>Categories</Link></li>



                            <li className='hidden lg:block'>|</li>


                            <li
                                className={`${hoveredIndex === 5 ? 'hovered' : ''} ${clickedIndex === 5 ? 'active' : ''}`}
                                onMouseEnter={() => handleMouseEnter(5)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick(5)}
                            ><a href='https://www.devmedicos.com/blog/'>Blog</a></li>
                            <li className='hidden lg:block'>|</li>
                            <li className={`${hoveredIndex === 6 ? 'hovered' : ''} ${clickedIndex === 6 ? 'active' : ''}`}
                                onMouseEnter={() => handleMouseEnter(6)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => {
                                    handleClick(6);
                                    onButtonClick();
                                }}><Link to='/faq'>Faqs</Link></li>
                            <li className='hidden lg:block'>|</li>

                            <li
                                className={`${hoveredIndex === 7 ? 'hovered' : ''} ${clickedIndex === 7 ? 'active' : ''}`}
                                onMouseEnter={() => handleMouseEnter(7)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => {
                                    handleClick(7);
                                    onButtonClick();
                                }}
                            ><Link to='/contact'>Contact Us</Link></li>
                        </ul>
                    </div>
                </nav>
            </header>

            <HeaderModal isOpen={isModalOpen} onClose={closeModal} />

        </>
    )
}
