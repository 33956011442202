import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { API_URLS } from '../api/urls';
import axios from 'axios';
import { GetInTouchForm } from '../modals/getInTouchForm';
import { ProductEnquiryModal } from '../modals/productEnquiryModal';
import { Helmet } from 'react-helmet-async';
import { ProductEnquiryModal1 } from '../modals/productEnwuiryModal1';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

interface Product {
  id: number;
  product_name: string;
  price: string;
  product_images: any;
  product_units: string;
  product_top_selling: number;
  product_url_slug: string;
  diseases: any[];
  salts: any[];
}

export const ProductListing = () => {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();

  const [page, setPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState<number[]>([1]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductID, setSelectedProductID] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [productPrice, setproductPrice] = useState('');

  const [image, setImage] = useState<{ image_url: string }[]>([]);



  const apiUrlFetch = location.pathname.startsWith('/categories');



  const apiUrl = location.pathname.startsWith('/categories')
    ? `${API_URLS.TopSellingProducts}?salt_url_slug=${slug}&page=${page}`
    : `${API_URLS.TopSellingProducts}?disease_url_slug=${slug}&page=${page}`;





  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const itemsPerPage = 18; // Number of products per page
  const [allProducts, setAllProducts] = useState([]); // Keep all products
  const [hasMorePages, sethasMorePages] = useState(0);
  const [hasLastPageData, setLastPageData] = useState<any>();



  const fetchProducts = async (page: any) => {
    try {
      const apiUrl = location.pathname.startsWith('/categories')
        ? `${API_URLS.TopSellingProducts}?salt_url_slug=${slug}&page=${page}`
        : `${API_URLS.TopSellingProducts}?disease_url_slug=${slug}&page=${page}`;

      const response = await axios.get(apiUrl + `&limit_value=${itemsPerPage}`);
      const result = response.data;

    
      setProducts(result.data);
      setTotalProducts(result.totalCount || 0);


      if (result.data.length === itemsPerPage) {
        sethasMorePages(currentPage);
        sethasMorePages((prev) => prev + 1)
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts(currentPage);
  }, [currentPage, slug]);


  const totalPages = Math.ceil(totalProducts / itemsPerPage);





  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts(page);
  }, [page]);


  const openModal = (
    productName: string,
    productId: number,
    productPrice: string,
    images: { image_url: string }[]
  ) => {

    setSelectedProductName(productName);
    setSelectedProductID(productId);
    setIsModalOpen(true);
    setproductPrice(productPrice);
    setImage(images);
  };


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductName('');
    setSelectedProductID(0);
    setproductPrice('');
    setImage([]);
  };


  const uniqueDiseases = new Map();

  products.forEach(product => {
    product.diseases.forEach(disease => {
      if (!uniqueDiseases.has(disease.name)) {
        uniqueDiseases.set(disease.name, disease.url_slug);
      }
    });
  });


  const uniqueDiseasesArray = Array.from(uniqueDiseases, ([name, url_slug]) => ({ name, url_slug }));



  const uniqueCategories = new Map();

  products.forEach(product => {
    product.salts.forEach(salt => {
      if (!uniqueCategories.has(salt.name)) {
        uniqueCategories.set(salt.name, salt.url_slug);
      }
    });
  });


  const uniqueCategoriesArray = Array.from(uniqueCategories, ([name, url_slug]) => ({ name, url_slug }));

  const capitalizeWords = (str: any) => {
    if (!str) return '';

    return str
      .split(' ') // Split string into words
      .map((word: string) => {
        // If the word contains a number (e.g., "5mg", "10mg"), leave it unchanged
        if (/\d/.test(word)) {
          return word; // Don't modify words with numbers
        }

        // Otherwise, capitalize the first letter of the word and make the rest lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' '); // Join the words back into a single string
  };


  return (
    <>
      {/* <Helmet>
        <title>{slug?.replace(/-/g, ' ')} Supplier | {slug?.replace(/-/g, ' ')} Wholesale Exporter in India</title>
        <meta name="description" content={`Buy ${slug?.replace(/-/g, ' ')} from Reliable ${slug?.replace(/-/g, ' ')} Supplier in India | Devmedicos is a leading Wholesale Exporter Offering High-Quality ${slug?.replace(/-/g, ' ')} at Competitive Prices.`} />
      </Helmet> */}

      <Helmet>
        <title>{capitalizeWords(slug?.replace(/-/g, ' '))} Supplier | {capitalizeWords(slug?.replace(/-/g, ' '))} Wholesale Exporter in India</title>
        <meta name="description" content={`Buy ${capitalizeWords(slug?.replace(/-/g, ' '))} from Reliable ${capitalizeWords(slug?.replace(/-/g, ' '))} Supplier in India | Devmedicos is a leading Wholesale Exporter Offering High-Quality ${capitalizeWords(slug?.replace(/-/g, ' '))} at Competitive Prices.`} />
      </Helmet>



      <section className='lg:py-8 py-5 bg-white'>
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className="flex flex-wrap -mx-4 items-center gap-y-8">
            <div className='xl:w-9/12 lg:w-8/12 w-full px-4'>
              <nav aria-label="Breadcrumb">
                <ol className="inline-flex items-center [&>*:not(:first-of-type)]:pl-4 [&>*:not(:last-of-type)]:pr-4 after:[&>*:not(:last-of-type)]:content-['/'] after:[&>*]:absolute [&>*]:relative after:[&>*]:right-0 [&_a]:text-primary-900 [&>*]:font-medium [&>*]:text-gray-700 [&_a:hover]:text-orange-600">
                  <li className="inline-flex items-center">
                    <Link to="/">Home</Link>
                  </li>
                  <li aria-current="page">Products</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>




      <section className='pb-16 bg-white'>
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className="flex flex-wrap gap-y-8 -mx-4">
            <div className='xl:w-9/12 lg:w-8/12 w-full px-4'>
              <div className="flex flex-wrap gap-y-8 -mx-4">
                {products.length > 0 ? products.map(product => (
                  <div key={product.id} className='xl:w-4/12 lg:w-6/12 sm:w-6/12 w-full px-4'>
                    <div className="rounded-xl shadow-[0_0_6px_1px_rgba(0,0,0,0.15)] bg-white text-center flex flex-col gap-y-4 h-full">
                      <div className="p-3 text-center h-[250px] [&_img]:w-full [&_img]:h-full [&_img]:object-contain">
                        <Link to={`/products/${product.product_url_slug}`}>
                          <img src={
                            product.product_images?.split(',')[0]
                              ? `/admin/storage/image/product/${product.product_images.split(',')[0]}`
                              : '/assets/images/no-img.webp'
                          }
                            alt={product.product_name || 'No image available'}
                            onError={(e: any) => {
                              e.target.src = '/assets/images/no-img.webp';
                            }} />
                        </Link>
                      </div>
                      <div className="px-3 flex flex-col gap-y-3">
                        <Link to={`/products/${product.product_url_slug}`}>
                          <h3 className="!font-roboto text-lg font-semibold text-[#2f2f2f] ">{product.product_name}</h3>
                        </Link>
                      </div>

                      <div className="mt-auto p-3 border-t flex gap-2">
                        <Link className="px-3 flex-1 py-2.5 font-montserrat text-sm font-bold text-slate-700 rounded-md border border-slate-700 hover:text-white hover:border-orange-500 hover:bg-orange-500" to={`/products/${product.product_url_slug}`}>Know More</Link>
                        <button onClick={() => openModal(
                          product.product_name,
                          product.id,
                          product.price,
                          product?.product_images || [])} type='button' className="px-3 flex-1 py-2.5 font-montserrat text-sm font-bold text-white rounded-md bg-[#ff8629] hover:bg-orange-500">Get Best Price</button>
                      </div>

                    </div>
                  </div>
                )) : (
                  <div className='w-full px-4'>
                    <p className="w-full text-center p-20 border border-slate-200 rounded-xl bg-slate-50 xl:min-h-[400px] flex justify-center items-center">No Products available</p>
                  </div>
                )}
              </div>


              <div className="flex justify-center mt-10">

                {products.length > 0 && (
                  <Stack spacing={2}>
                    <Pagination
                      count={hasMorePages}
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                        fetchProducts(value);
                      }}
                      color="primary"
                    />
                  </Stack>
                )}


              </div>



            </div>
            <div className='xl:w-3/12 lg:w-4/12 w-full px-4'>
              <div className="border border-slate-300 rounded-xl flex flex-col mb-8">
                <div className="pt-4 px-4">
                  <h3 className="lg:text-2xl md:text-xl text-xl font-semibold">Get in Touch</h3>
                </div>
                <div className='p-4'>
                  <GetInTouchForm />
                </div>
              </div>




              {apiUrlFetch ?


                <div className="border border-slate-300 rounded-xl flex flex-col">
                  <div className="py-4 px-4 border-b">
                    <h3 className="lg:text-2xl md:text-xl text-xl font-semibold">Groups</h3>
                  </div>

                  {products.length > 0 ? (
                    <>

                      {uniqueDiseasesArray.length > 0 ? (
                        <ul className='[&>*]:flex [&>*]:flex-col [&>*]:py-1.5 [&>*]:px-4 [&>*:not(:last-of-type)]:border-b [&>*]:cursor-pointer'>
                          {uniqueDiseasesArray.map((disease, i) => (
                            <li key={i}>
                              <Link to={`/group/${disease.url_slug}`}>{disease.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className='px-4'>No Related Disease Found</p>
                      )}
                    </>
                  ) : (
                    <p className='px-4'>No Disease Found</p>
                  )}

                </div>

                :

                <div className="border border-slate-300 rounded-xl flex flex-col">
                  <div className="py-4 px-4 border-b">
                    <h3 className="lg:text-2xl md:text-xl text-xl font-semibold">Categories</h3>
                  </div>

                  {products.length > 0 ? (
                    <>

                      {uniqueCategoriesArray.length > 0 ? (
                        <ul className='[&>*]:flex [&>*]:flex-col [&>*]:py-1.5 [&>*]:px-4 [&>*:not(:last-of-type)]:border-b [&>*]:cursor-pointer'>
                          {uniqueCategoriesArray.map((salt, i) => (
                            <li key={i}>
                              <Link to={`/categories/${salt.url_slug}`}>{salt.name}</Link>
                              {/* {salt.name} */}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No Related Categories Found</p>
                      )}
                    </>
                  ) : (
                    <p className='p-4'>No Categories Found</p>
                  )}
                </div>
              }

            </div>
          </div>
        </div>

        <ProductEnquiryModal1 isOpen={isModalOpen} onClose={closeModal} productName={selectedProductName} productID={selectedProductID} productQuantity={undefined} productUnit={undefined} productPrice={productPrice} image={image} />
      </section>
    </>
  );
}




