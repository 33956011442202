import React, { useEffect, useState } from 'react'

export const ModalCounter = () => {

    const [isAutoModalOpen, setIsAutoModalOpen] = useState(false);
    const [closeCount, setCloseCount] = useState(0);
  
    const closeAutoModal = () => {
      setIsAutoModalOpen(false);
      setCloseCount((prevCount) => prevCount + 1);
    };
  
    useEffect(() => {
      
      if (closeCount < 2) {
        const timer = setInterval(() => {
          setIsAutoModalOpen(true);
        }, 30000);
  
        
        return () => clearInterval(timer);
      }
    }, [closeCount]);
    
  return [isAutoModalOpen,closeAutoModal]
}
