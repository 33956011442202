import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { API_URLS } from '../api/urls';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Link, useNavigate } from 'react-router-dom';
import { ProductEnquiryModal } from '../modals/productEnquiryModal';
import './css/loader.css';
import { ProductImage } from '../model/productDetails';
import { DetailsEnquiryModal } from '../modals/detailsEnquiryModal';
import { ProductEnquiryModal1 } from '../modals/productEnwuiryModal1';

interface Product {
  id: number;
  product_name: string;
  price: string;
  product_images: any;
  product_units: string;
  product_top_selling: number;
  product_url_slug: string;
  productPrice: any
}

export const TopSellingProductList = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductID, setSelectedProductID] = useState<any>(null);
  const [image, setImage] = useState<{ image_url: string }[]>([]);

  const [productPrice, setproductPrice] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productUnit, setProductUnit] = useState("");


  useEffect(() => {
    fetchProducts(page);
  }, [page]);

  const fetchProducts = (page: number = 1) => {
    setLoading(true);
    axios
      .get(`${API_URLS.TopSellingProducts}?page=${page}`)
      .then((response) => {
        const { data } = response.data;

        const topSellingProducts = data.filter(
          (product: Product) => product.product_top_selling === 1
        );

        if (topSellingProducts.length > 0) {
          setProducts((prevProducts) => [...prevProducts, ...topSellingProducts]);
        } else {
          setHasMore(false);
        }

      })
      .catch((error) => {
        console.error('Error fetching the products:', error);
        setLoading(false);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,

    afterChange: (currentSlide: number) => {

      if (currentSlide >= products.length - 6 && hasMore) {
        loadMore();
      }
    },
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  


  const openModal = (
    productName: string,
    productId: number,
    productPrice: string,
    images: { image_url: string }[],
    productUnit: string
  ) => {

    setSelectedProductName(productName);
    setSelectedProductID(productId);
    setIsModalOpen(true);
    setproductPrice(productPrice);
    setImage(images);
    setProductUnit(productUnit);
  };


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductName('');
    setSelectedProductID(0);
    setproductPrice('');
    setImage([]);
    setProductUnit('');
  };

  return (
    
    <section className="lg:py-16 py-10 bg-[#d1e0e6]">
      {products && products.length > 0 ?
      <div className="max-w-[1450px] mx-auto px-4">
        <div className="cms-content lg:mb-8 mb-4 lg:[&_h2]:text-[40px] md:[&_h2]:text-4xl sm:[&_h2]:text-3xl [&_h2]:text-2xl font-semibold text-center">
          <h2>Top Selling Products</h2>
        </div>
        <div className="products-slider 
        [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div]:px-3 
        [&_.slick-slider_.slick-list_.slick-track]:flex 
        [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:float-none 
        [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:h-auto 
        [&_.slick-slider_.slick-list_.slick-track_.slick-slide_img]:inline-block 
        [&_.slick-slider_.slick-list_.slick-track_.slick-slide_>div]:h-full 
        [&_.slick-slider_.slick-arrow]:-translate-y-1/2 [&_.slick-slider_.slick-arrow]:z-10 
        [&_.slick-slider_.slick-arrow]:w-5 [&_.slick-slider_.slick-arrow]:h-5 
        [&_.slick-slider_.slick-arrow]:border-0 [&_.slick-slider_.slick-arrow]:border-l-4 
        [&_.slick-slider_.slick-arrow]:border-b-4 [&_.slick-slider_.slick-arrow]:border-solid 
        [&_.slick-slider_.slick-arrow]:border-[#5b5b5b] 
        [&_.slick-slider_.slick-arrow:hover]:border-[#ff8629] 
        2xl:[&_.slick-slider_.slick-prev]:-left-4 
        [&_.slick-slider_.slick-prev]:rotate-45 
        [&_.slick-slider_.slick-prev]:before:bg-none 
        2xl:[&_.slick-slider_.slick-next]:-right-4 
        [&_.slick-slider_.slick-prev]:left-0 
        [&_.slick-slider_.slick-next]:right-0 
        [&_.slick-slider_.slick-next]:rotate-[-135deg] 
        [&_.slick-slider_.slick-next]:before:bg-none">
          {products && products.length > 0 ?
            <Slider {...settings}>
              {products.map((product) => (
                <div
                  key={product.id}
                  className="p-3 rounded shadow bg-white text-center !flex flex-col gap-y-4 h-full"
                >
                  <div className="text-center h-[150px] [&_img]:w-full [&_img]:h-full [&_img]:object-contain border-2 border-gray-300" >
                    <Link to={`/products/${product.product_url_slug}`}>
                      <img
                        src={
                          product.product_images?.split(',')[0]
                            ? `/admin/storage/image/product/${product.product_images.split(',')[0]}`
                            : '/assets/images/no-img.webp'
                        }
                        alt={product.product_name || 'No image available'}
                        onError={(e: any) => {
                          e.target.src = '/assets/images/no-img.webp';
                        }}
                        width={185} height={185}
                        loading="lazy"
                      />
                    </Link>
                  </div>

                  <div className="flex flex-col gap-y-3 [&_h3]:h-12">
                    <Link to={`/products/${product.product_url_slug}`}>
                      <h3
                        data-tooltip-id={`tooltip-${product.id}`}
                        className="!font-roboto text-base text-[#595959] line-clamp-2 cursor-pointer capitalize"
                      >
                        {product.product_name}
                      </h3>
                    </Link>
                    <ReactTooltip className='capitalize'
                      id={`tooltip-${product.id}`}
                      content={product.product_name}
                      place="top"
                    />
                    {product.price ?
                      <p className="font-roboto text-base font-bold text-black capitalize">
                        Price : ₹ {product.price} {product.product_units ? '/' : null} {product.product_units}
                      </p>
                      : null
                    }
                  </div>
                  <div className="mt-auto">
                    <button onClick={() => openModal(
                      product.product_name,
                      product.id,
                      product.price,
                      product?.product_images || [],
                      product.product_units
                    )}
                      className="px-6 py-1.5 block w-full font-montserrat text-sm font-bold text-white rounded-md bg-[#ff8629] hover:bg-orange-500"
                    >
                      Get Best Price
                    </button>

                  </div>
                </div>
              ))}
              {loading && (
                <div className="p-3 flex items-center justify-center h-full relative">
                  <div className="loader absolute inset-0 justify-center items-center">
                    <div className="spinner"></div>
                  </div>
                </div>
              )}
            </Slider>
            : <p>No Product Found </p>}

        </div>
      </div>
      : null }
      <ProductEnquiryModal1 isOpen={isModalOpen} onClose={closeModal} productName={selectedProductName} productID={selectedProductID} productQuantity={undefined} productPrice={productPrice} image={image} productUnit={productUnit} />
    </section>
  );
};
