import axios from 'axios';
import React, { useState } from 'react'
import AlertMessage from '../components/AlertMessage';
import { API_URLS } from '../api/urls';


interface FormData {
    name: string;
    email: string;
    contact_no: string;
    message: string;
    form_submit_url: string;
    form_name: string;
}



export const ContactUsForm = () => {

    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: window.location.href,
        form_name: 'Contact Us Form',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: '',
        form_name: 'Contact Us Form',
    });


    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Product Inquiry Modal',
        });
        setFormErrors({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Product Inquiry Modal',
        });
    };

    const validateForm = (): boolean => {
        let valid = true;
        const newErrors = {
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Product Inquiry Modal',
        };

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        } else if (!/^[A-Za-z]+( [A-Za-z]+)*$/.test(formData.name.trim())) {
            newErrors.name = 'Name should not contain numbers';
            valid = false;
        }

        // const isValidEmail = (email: string) => {
        //     // Email validation regex (standard email format with additional checks)
        //     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
        
        //     // Check if email matches the regex
        //     if (!emailRegex.test(email)) {
        //         return false;
        //     }
        
        //     // Extract the local part before '@'
        //     const localPart = email.split('@')[0];
        
        //     // Check if local part contains only digits (numbers)
        //     if (/^\d+$/.test(localPart)) {
        //         return false; // Only numbers are not allowed before '@gmail.com'
        //     }
        
        //     // Check for extra characters after .com (if any)
        //     const domain = email.split('@')[1]; // Extract domain part after '@'
        //     const topLevelDomain = domain.split('.')[1]; // Extract top-level domain (e.g., 'com', 'org')
        
        //     if (topLevelDomain && topLevelDomain.toLowerCase() === 'com') {
        //         const partsAfterCom = domain.split('.').slice(2); // Get parts after '.com'
        
        //         if (partsAfterCom.length > 0) {
        //             return false; // Extra characters found after .com
        //         }
        //     }
        
        //     return true; // Email is valid
        // };

        // if (!formData.email) {
        //     newErrors.email = 'Email is required';
        //     valid = false;
        // }

        // else if (!isValidEmail(formData.email)) {
        //     newErrors.email = 'Please enter a valid email address';
        //     valid = false;
        // }

        if (!formData.contact_no) {
            newErrors.contact_no = 'Phone number is required';
            valid = false;
        } else if (!/^[+\d]+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number can only contain numbers and + sign';
            valid = false;
        } else if (formData.contact_no.length < 10) {
            newErrors.contact_no = 'Phone number must be at least 10 digits long';
            valid = false;
        } else if (/(\d)\1{5,}/.test(formData.contact_no.trim())) {
            newErrors.contact_no = 'Phone number should not have more than 5 consecutive identical digits';
            valid = false;
        } else if (formData.contact_no.startsWith('+') && !/^\+\d+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number must start with a + followed by digits';
            valid = false;
        } else if (formData.contact_no.length > 15) { // Adjust this based on the maximum length you accept
            newErrors.contact_no = 'Phone number cannot exceed 15 digits';
            valid = false;
        }


        
        if (!formData.message) {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setFormErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);
        try {
            const response = await axios.post(`${API_URLS.FormSubmit}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response) {
                resetForm();
                setAlertMessage({ message: 'Your inquiry has been received. Our team will get back to you soon.', type: 'success' });
            } else {
                setAlertMessage({ message: 'Error while submission', type: 'error' });
            }
        } catch (error) {
            setAlertMessage({ message: `Error while booking: ${error}`, type: 'error' });
        } finally {
            setLoading(false);
        }
    };


    const [alertMessage, setAlertMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(null);


    const closeAlertMessage = () => {
        setAlertMessage(null);
    };

    return (
        <div className='relative'>

            
            <h3 className='mb-4 text-xl font-bold text-primary-400'>Contact Us</h3>


            <div className="mb-2">
                <input type="text"
                    value={formData.name} onChange={handleInputChange}
                    id="name"
                    name="name"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Name"
                />
                {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
            </div>

            <div className="mb-2">
                <input type="tel"
                    value={formData.contact_no}
                    onChange={handleInputChange}
                    id="contact_no"
                    name="contact_no"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Contact No"
                />
                {formErrors.contact_no && <p className="text-red-500 text-xs mt-1">{formErrors.contact_no}</p>}
            </div>

            <div className="mb-2">
                <input type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    id="email"
                    name="email"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Email ID" />
                {/* {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>} */}
            </div>

            <div className="mb-2">
                <textarea
                    value={formData.message}
                    onChange={handleInputChange}
                    id="message"
                    name="message"
                    className="w-full px-4 py-2 text-black border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] focus:outline-0"
                    placeholder="Your message">
                </textarea>
                {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
            </div>
            <div>
                <button type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-8 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
            </div>
            <div className="[&_.alert-box]:mx-auto [&_>div]:relative">
                {alertMessage && (
                    <AlertMessage
                        message={alertMessage.message}
                        onClose={closeAlertMessage}
                        type={alertMessage.type}
                    />
                )}
            </div>

        </div>
    )
}
