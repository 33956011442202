import React, { useState } from 'react'
import { ContactUsForm } from '../modals/contactUsForm'
import { Link } from 'react-router-dom'
import { HeaderModal } from '../modals/headerModal'
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import { DetailsEnquiryModal } from '../modals/detailsEnquiryModal'

export const Footer = () => {


  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);


  return (
    <>
      <footer>
        <div className="footer-top font-montserrat py-6 text-white bg-[#484848]">
          <div className='max-w-[1450px] mx-auto px-4'>
            <ul className='flex flex-wrap items-center lg:justify-between justify-center xl:gap-8 gap-4'>
              <li>
                <button onClick={openModal} className='flex items-center gap-x-2 lg:px-8 lg:py-4 px-4 py-2 text-white rounded-md bg-[#ff8629] hover:bg-orange-500' aria-label="Get a Quote">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 28.35 28.35" xmlSpace="preserve" className='w-8 h-8 fill-white'>
                    <g>
                      <path d="M18.74,24.36c0-0.83,0.01-1.63,0-2.43c0-0.25,0.03-0.44,0.29-0.56c0.22-0.1,0.4-0.28,0.61-0.4c0.49-0.28,0.83-0.7,1.07-1.2
                    c0.05-0.1,0.12-0.2,0.22-0.35c0.02,0.12,0.03,0.17,0.03,0.22c0,1.91,0,3.82,0,5.73c0,0.51-0.19,0.92-0.68,1.12
                    c-0.2,0.08-0.43,0.1-0.65,0.1c-1.43,0.01-2.87,0-4.3,0c-4.08,0-8.15,0-12.23-0.01c-0.29,0-0.62-0.09-0.88-0.23
                    C1.88,26.16,1.8,25.8,1.8,25.4c0.01-2.62,0-5.24,0-7.86c0-4.81,0.02-9.62-0.01-14.43C1.78,2.12,2.38,1.75,3.15,1.75
                    c5.48,0.01,10.95,0,16.43,0.01c0.95,0,1.34,0.43,1.39,1.38c0.05,0.93-0.11,1.75-0.65,2.54c-0.5,0.74-0.91,1.55-1.36,2.32
                    c-0.05,0.08-0.11,0.16-0.22,0.31c0-1.48,0-2.88,0-4.31c-4.93,0-9.82,0-14.73,0c0,6.79,0,13.57,0,20.37
                    C8.91,24.36,13.8,24.36,18.74,24.36z"/>
                      <path d="M22.95,4.54c1.45,0.22,2.67,0.82,3.47,2.11c0.18,0.28,0.18,0.56,0,0.87c-1.6,2.75-3.18,5.51-4.77,8.26
                    c-0.7,1.21-1.37,2.43-2.11,3.62c-0.2,0.32-0.55,0.56-0.87,0.78c-0.96,0.66-1.94,1.29-2.91,1.93c-0.24,0.16-0.49,0.29-0.77,0.12
                    c-0.27-0.16-0.26-0.44-0.24-0.71c0.07-1.28,0.11-2.57,0.23-3.85c0.03-0.39,0.23-0.78,0.42-1.13c1.45-2.54,2.91-5.07,4.38-7.6
                    c0.75-1.29,1.52-2.58,2.25-3.88C22.24,4.67,22.54,4.54,22.95,4.54z M18.16,19.57c-0.67-0.73-1.43-1.2-2.4-1.38
                    c-0.03,0.43-0.07,0.83-0.08,1.22c0,0.08,0.1,0.18,0.19,0.23c0.33,0.2,0.67,0.39,1.01,0.57c0.08,0.04,0.21,0.05,0.28,0.01
                    C17.48,20.03,17.8,19.81,18.16,19.57z"/>
                      <path d="M11.5,10.45c1.35,0,2.7-0.01,4.06,0c0.73,0.01,1.13,0.42,1.13,1.11c0,0.63-0.45,1.08-1.14,1.08
                    c-2.79,0.01-5.58,0.01-8.36,0c-0.67,0-1.14-0.47-1.15-1.08c-0.01-0.62,0.4-1.08,1.08-1.1c1.12-0.03,2.24-0.02,3.36-0.03
                    c0.34,0,0.69,0,1.03,0C11.5,10.44,11.5,10.45,11.5,10.45z"/>
                      <path d="M11.34,8.4c-1.35,0-2.71,0-4.06,0c-0.47,0-0.85-0.13-1.1-0.55c-0.42-0.71,0.08-1.64,0.9-1.65c2.86-0.01,5.73-0.01,8.59,0
                    c0.58,0,1,0.48,1.02,1.06c0.01,0.63-0.38,1.1-0.98,1.11c-1.45,0.02-2.91,0.01-4.36,0.01C11.34,8.39,11.34,8.4,11.34,8.4z"/>
                      <path d="M9.27,16.9c-0.68,0-1.35,0-2.03,0c-0.71,0-1.21-0.46-1.22-1.09c-0.01-0.65,0.5-1.13,1.22-1.13c1.35-0.01,2.71-0.01,4.06,0
                    c0.67,0,1.16,0.48,1.16,1.11c0,0.64-0.49,1.1-1.16,1.11C10.62,16.9,9.95,16.9,9.27,16.9z"/>
                      <path d="M9.38,20.58c0.23,0.12,0.47,0.21,0.67,0.35c0.16,0.11,0.27,0.28,0.41,0.41c0.08,0.07,0.17,0.16,0.27,0.17
                    c0.94,0.05,1.88,0.07,2.82,0.12c0.26,0.01,0.41,0.16,0.4,0.43c-0.01,0.3-0.22,0.38-0.46,0.36c-0.52-0.05-1.03-0.13-1.55-0.21
                    c-0.48-0.07-0.95-0.03-1.4,0.18c-0.47,0.22-0.78,0.07-0.92-0.49c-0.21,0.27-0.43,0.59-0.77,0.34c-0.13-0.1-0.12-0.39-0.17-0.59
                    c-0.05,0.05-0.15,0.13-0.24,0.22c-0.18,0.18-0.42,0.19-0.56,0.02c-0.09-0.1-0.08-0.35-0.03-0.5c0.08-0.22,0.25-0.41,0.38-0.61
                    c-0.02-0.02-0.05-0.04-0.07-0.07c-0.16,0.17-0.34,0.34-0.49,0.52c-0.47,0.56-0.93,1.12-1.4,1.69c-0.04,0.05-0.08,0.1-0.13,0.15
                    c-0.18,0.19-0.4,0.21-0.6,0.05c-0.22-0.17-0.14-0.38,0-0.56c0.51-0.63,1-1.27,1.54-1.88c0.46-0.52,0.96-1.02,1.47-1.49
                    c0.19-0.17,0.47-0.28,0.73-0.34c0.26-0.06,0.48,0.09,0.46,0.38c-0.02,0.32-0.11,0.64-0.18,0.96C9.52,20.32,9.46,20.41,9.38,20.58z"
                      />
                    </g>
                  </svg>
                  <span className='xl:text-2xl lg:text-xl md:text-lg text-base font-semibold max-sm:hidden'>Get A Quote</span>
                </button>
              </li>
              <li>
                <a href="whatsapp://send?phone=9011200003" className='flex items-center gap-x-2 lg:px-8 lg:py-4 px-4 py-2 text-white rounded-md bg-[#2fb944]' aria-label="Whats App">
                  <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 308 308" xmlSpace="preserve" className='w-8 h-8 fill-white'>
                    <g id="XMLID_468_">
                      <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                      c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                      c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                      c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                      c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                      c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                      c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                      c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                      c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                      C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                      <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                      c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                      c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                      M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                      l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                      c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                      C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                    </g>
                  </svg>
                  <span className='xl:text-2xl text-lg font-semibold max-sm:hidden'>Chat with Us</span>
                </a>
              </li>
              <li>
                <a href='tel:+919011200003' className='flex items-center gap-x-2 text-white' aria-label="Call Us">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340.16 340.16" xmlSpace="preserve" className='w-10 h-10 p-2 fill-primary-900 bg-white rounded-full'>
                    <g>
                      <path d="M8.35,102.71c-0.56-12.44,4.27-24.39,15.11-34.1c7.31-6.55,13.95-13.85,21-20.7c12.03-11.71,24.91-11.68,36.86,0.13 
                      c12.32,12.18,24.58,24.44,36.75,36.77c11.95,12.11,11.91,25.17-0.04,37.33c-7.13,7.25-14.29,14.46-21.62,21.5
                      c-2.72,2.61-3.11,4.92-1.54,8.35c7.28,15.96,17.77,29.69,29.45,42.56c18.36,20.23,38.39,38.37,63.39,50.31
                      c3.62,1.73,5.92,1.57,8.71-1.36c7.01-7.36,14.27-14.47,21.5-21.61c12.25-12.09,25.01-12.14,37.13-0.13
                      c12.3,12.2,24.58,24.43,36.79,36.73c12.11,12.2,12.04,25.42-0.08,37.68c-7.49,7.58-15,15.16-22.51,22.73
                      c-11.26,11.35-25.15,14.1-40.42,12.39c-33.91-3.81-63.23-19.11-90.85-37.67c-55.03-36.99-96.41-85.5-120.84-147.67
                      C11.93,132.69,8.4,119.01,8.35,102.71z" />
                      <path d="M331.85,161.21c-9.2,1.59-17.79,3.08-26.85,4.64c-6.16-34.09-21.05-63.48-45.93-87.45c-24.7-23.79-54.23-37.99-88.71-43.09
                      c1.26-8.98,2.47-17.64,3.77-26.91c39.99,5.54,74.23,21.82,102.98,49.45C306.61,86.19,324.92,120.47,331.85,161.21z" />
                      <path d="M266.32,165.59c-8.63,1.47-17.34,2.95-26.14,4.45c-8.91-39.86-33.3-62.99-73-70.94c1.2-8.65,2.41-17.31,3.6-25.86
                      C209.49,69.69,265.89,124.19,266.32,165.59z" />
                    </g>
                  </svg>
                  <span className='xl:text-2xl text-lg font-semibold max-sm:hidden'>Call Now: +91 9011200003</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-top lg:pt-16 pt-10 pb-8 text-white bg-[#292929]">
          <div className='max-w-[1450px] mx-auto px-4'>
            <div className="flex flex-wrap -mx-4 gap-y-8">
              <div className='xl:w-3/12 lg:w-4/12 md:w-4/12 sm:w-6/12 w-full px-4'>
                <Link to="/" aria-label="Dev Medical"><img src="/assets/images/dev-medical-footer.svg" alt="Dev Medical" width={331} height={163} loading="lazy" /></Link>
              </div>
              <div className='xl:w-2/12 lg:w-4/12 md:w-4/12 w-6/12 px-4'>
                <div>
                  <h3 className='mb-4 text-xl font-bold text-primary-400'>Company</h3>
                  <ul className='flex flex-col gap-y-2 [&_li_a]:block [&_li_a:hover]:text-primary-300 [&_li_a]:duration-200'>

                    <li><a href="https://www.devmedicos.com/blog/">Blog</a></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/contact">Contact</Link></li>

                  </ul>
                </div>
              </div>
              <div className='xl:w-2/12 lg:w-4/12 md:w-4/12 w-6/12 px-4'>
                <div>
                  <h3 className='mb-4 text-xl font-bold text-primary-400'>Products</h3>
                  <ul className='flex flex-col gap-y-2 [&_li_a]:block [&_li_a:hover]:text-primary-300 [&_li_a]:duration-200'>
                    <li><Link to="/group/immunosuppressive-drug">Immunosuppressive Drugs</Link></li>
                    <li><Link to="/group/anti-cancer-medicines">Anti Cancer Medicines</Link></li>
                    <li><Link to="/group/anti-cancer-injection">Anti Cancer Injection</Link></li>
                    <li><Link to="/group/hiv-drugs">Hiv Drugs</Link></li>
                    <li><Link to="/group/fertility-enhancer-drugs">Fertility Enhancer</Link></li>
                  </ul>
                  <div className='mt-4'>
                    <Link to="/groups" className='font-bold text-[#ff8629]'>View All</Link>
                  </div>
                </div>
              </div>
              <div className='xl:w-2/12 lg:w-4/12 md:w-4/12 sm:w-6/12 w-full px-4'>
                <div className='mb-8'>
                  <h3 className='mb-4 text-xl font-bold text-primary-400'>Follow Us</h3>
                  <ul className='flex flex-wrap gap-2 [&_li_a]:flex [&_li_a]:justify-center [&_li_a]:items-center [&_li_a]:w-12 [&_li_a]:h-12 [&_li_a]:text-2xl [&_li_a]:text-[#292929] [&_li_a]:rounded-full [&_li_a:hover]:bg-primary-300 [&_li_a]:bg-white [&_li_a]:duration-200'>
                    <li><Link to="https://www.facebook.com/devmedical2020/" aria-label="About Facebook"><i className='ri-facebook-fill'></i></Link></li>
                    <li><Link to="https://x.com/DevMedicalPune" aria-label="About Twitter X"><i className='ri-twitter-x-fill'></i></Link></li>
                    <li><Link to="https://www.linkedin.com/in/dev-medical-80825432a/" aria-label="About Linkedin"><i className='ri-linkedin-fill'></i></Link></li>
                    <li><Link to="https://www.instagram.com/devmedicals/" aria-label="About Instagram"><i className="ri-instagram-line"></i></Link></li>
                    <li><Link to="https://www.youtube.com/@devmedical9984" aria-label="About Youtube"><i className="ri-youtube-line"></i></Link></li>
                    <li>
                      <Link to={`https://wa.me/9011200003`} target="_blank" rel="noopener noreferrer" aria-label="About WhatsApp">
                        <i className="ri-whatsapp-line"></i>
                      </Link>
                    </li>
                  </ul>

                </div>
                <div className='mb-0'>
                  <h3 className='mb-4 text-xl font-bold text-primary-400'>Certification</h3>
                  <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                    <img className="w-full rounded" src="/assets/images/export-dev.webp" alt='Import and Export' width={736} height={1000} loading="lazy" />
                    <img className="w-full rounded" src="/assets/images/certificate-of-registration-dev.webp" alt='Certificate of Registration' width={716} height={1000} loading="lazy" />
                  </SlideshowLightbox>
                </div>
              </div>
              <div className='xl:w-3/12 lg:w-4/12 md:w-8/12 w-full px-4'>
                <ContactUsForm />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top font-montserrat py-3 text-white border-t border-[#5a5a5a] bg-[#292929]">
          <div className='max-w-[1450px] mx-auto px-4 text-center'>
            <span className='font-roboto text-base text-[#999999]'>&copy; Dev Medical. All Rights Reserved (Terms of Use)</span>
          </div>
        </div>
        <HeaderModal isOpen={isModalOpen} onClose={closeModal} />
      </footer>
      <div className="rcb-btn fixed z-20 top-1/2 right-0 -translate-y-1/2 rotate-180">
        <button type='button' onClick={openModal} className='m-0 px-2 py-4 sm:text-base text-sm font-montserrat font-bold text-nowrap tracking-wider text-white border-0 bg-[#ff8629]'>Request a call back</button>
      </div>
      <div className="fixed bottom-5 right-6 z-10 flex flex-col lg:gap-y-3 gap-y-2">
        <div className=' lg:h-14 lg:w-14 h-12 w-12 relative'>
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#ff8629] opacity-75"></span>
          <a href='tel:9011200003' className="relative flex justify-center items-center rounded-full lg:h-14 lg:w-14 h-12 w-12 text-white bg-[#ff8629] animate-zoom-in-90" aria-label="Call Us"><i className="ri-customer-service-2-line text-3xl"></i></a>
        </div>
        <div className='relative z-1'>
          <a href='whatsapp://send?phone=9011200003' className="relative flex justify-center items-center rounded-full lg:h-14 lg:w-14 h-12 w-12" aria-label="Whats App">
            <img src="/assets/images/whatspp-icon.gif" alt="WhatsApp" width={48} height={48} className="h-full w-full object-contain" loading="lazy" />
          </a>
        </div>
      </div>

      <HeaderModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  )
}
